@import 'foundation_and_overrides.scss';

html, body {
  height: 100%;
  min-height: 100%;
}

#page-content {
  min-height: 100%;
  padding-bottom: 90px;
  margin-top: -55px;
  padding-top: 65px;
}

#page-footer {
  position: relative;
  clear: both;
  color: #fff;
  height: 45px;
  margin-top: -37px;

  p {
    margin: 0;
    padding: 14px 0 8px 0;
  }
}

.progress {
  color: #fff;
  text-align: center;
  font-size: $small-font-size;

  .meter {
    line-height: 1.5;
  }
}

.top-bar {
  .name {
    margin-right: 12px;

    img {
      max-height: 45px;
    }
  }

  .top-bar-section {
    ul li {
      > a.button {
        padding: 0;
        height: inherit;
      }
    }

    form {
      .columns {
        padding-left: 0;
        padding-right: 0;
      }
      select {
        margin-bottom: 0;
      }
    }
  }
}

.side-nav {
  &.panel {
    li {
      margin: 0;
    }
  }
  label, input {
    margin: 0;
  }
}

table {
  width: 100%;

  th, td {
    &.reorder {
      width: 70px !important;
      text-align: right;
    }

    &.position {
      width: 100px !important;
    }
  }

  th {
    white-space: nowrap;
  }

  td {
    hyphens: auto;
    word-break: break-word;
  }
}

img.th, .th > img {
  background: rgba(142, 142, 147, 0.8);
}

.button-group.tiny,
.button-group.small {
  button, .button {
    font-size: rem-calc(11);
    padding: rem-calc(7);
    margin-bottom: 0;
  }

  &.clearfix {
    white-space: nowrap;
  }
}

.button-group.small {
  button, .button {
    font-size: rem-calc(13);
    padding: rem-calc(9);
  }
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

label > input[type="checkbox"],
label > input[type="radio"] {
  margin-right: rem-calc(3);
}

select {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  padding-right: 1.5em;
}

.hint {
  font-size: $input-error-message-font-size;
  margin-bottom: $input-font-size;
  color: $input-font-color;
}
input ~ .hint {
  display: block;
  margin-top: -$input-font-size;
}
.error input ~ .hint,
.error textarea ~ .hint,
.error select ~ .hint {
  margin-top: 0.05rem;
  margin-bottom: 0.4rem;
}
label {
  display: inline-block;
}

.simple_form {
  .preview {
    .th {
      margin-bottom: 0;
    }
  }
  label {
    margin-bottom: 0;
  }
  label[for*="translations"] {
    float: right;
  }

  .input-group {
    &.row {
      margin-bottom: 1rem;
    }
    &.hidden,
    .selectize-control {
      margin-bottom: 0;
    }

    .checkbox {
      display: block;

      input[type="file"], input[type="checkbox"], input[type="radio"], select {
        margin-bottom: 0;
      }
    }
  }

  input, select, textarea {
    margin-bottom: 0 !important;
  }
}

.form-actions {
  margin-top: $panel-margin-bottom;
  @include grid-column(
    // Control the number of columns
    $columns:10,
    // Choose the number of columns to offset this element by
    $offset:2
  );
}

.panel {
  &.alert {
    background: $alert-color;

    h1, h2, h3, h4, h5, h6, p {
      color: #fff;
    }
  }
}

.alert-box {
  margin-top: $alert-bottom-margin;
}

.form-block {
  &.time {
    select {
      width: auto;
    }
  }
}

.day-wrapper {
  padding: 0;
  border: 0 none;
  margin: 3px 0;

  select {
    width: auto;
  }

  legend {
    display: none;
  }
}

.heading .actions {
  text-align: right;
  margin-top: $header-bottom-margin * $header-line-height;
}

.tooltip img {
  max-width: 300px;
}

.tabs-content > fieldset.content {
  padding: $fieldset-padding;
  float: none;
}

.sorter {
  cursor: move;
  font-size: 1.2em;

  &:active {
    cursor: grabbing;
  }
}

#map-canvas {
  width: 100%;
  margin-bottom: 1rem;

  img, object, embed {
    max-width: inherit;
  }
}

.button.active {
  background-color: scale-color($button-bg-color, $lightness: $button-function-factor);
}

.button.dropdown {
  padding-right: 1.5625rem !important;

  &::after {
    right: 0.40625rem;
  }
}

.slideshow-wrapper .preloader {
  border: 2px solid;
  width: 7px;
}
