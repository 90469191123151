div.dataTables_info {
  float: left;
  padding-top: 2px;
  font-size: 0.875em;
}

div.dataTables_paginate {
  float: right;
  margin: 0;
}
